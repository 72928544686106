import React from "react"
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import Hero from "../components/hero"
import TestimonialSection from "../components/testimonials-section"
import ArrowRight from "../components/svg/arrowRight"
import Cta from "../components/cta"
import Seo from "../components/seo"
import ChevronDown from "../components/svg/chevronDown"

const WebDevPage = () => (
    <Layout>
        <Seo title="Web developers Sydney & Canberra | Web development Sydney" 
            description="Experienced Sydney web developers. Launch Lab are highly experienced Australian web developers and designers. We develop websites, startups and applications."
            pathname="/web-development/"
        />

        <Hero
            h1="Web Developers Sydney and Canberra"  
            h2="We're a highly experienced Australian website and application development team"
        />

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                        data-sal="slide-right" 
                        data-sal-easing="ease-in-out"
                        data-sal-duration="400"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">100+</span>
                            <p>We've developed more than 100 websites, startups, chatbots and apps.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">360</span>
                            <p>We offer a 360 degree service from web design to web development.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">SYD / CAN</span>
                            <p>Our Python and React work is done onshore in Sydney and Canberra.</p>
                        </div>
                    </div>
                    <div className="work-jumbo-wrapper mt-4 with-radius">
                            <StaticImage 
                                src="../images/launch-lab-work-2024.png"
                                alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                                className="with-radius"
                            />
                    </div>
                    <div className="flex flex-jc">
                        <Link to="/contact/" className="btn btn-lg btn-pink">
                        <span className="flex flex-ac">
                                Start your project
                            <ArrowRight />
                        </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Web &amp; application development</h3>
                        </div>

                        <div className="col-67 long-text"
                            data-sal="slide-up" 
                            data-sal-easing="ease-in-out"
                            data-sal-duration="400"
                        >
                            <p>
                            All of Launch Lab's web development work is completed in Australia by a <strong>senior team of web developers</strong>.
                            </p>
                            <p>
                                We've completed projects for large brands like Xero-owned Waddle, <Link to="/aws-infrastructure/">AWS hosting &amp; infrastructure</Link> work for NSW Government, chatbots for City of Sydney and are trusted by entreprenuers to develop startups that require custom application development using <Link to="/python-developer/">Python</Link> and <Link to="/react-developers-sydney-canberra/">React</Link>.
                            </p>
                            <p>
                                The majority of the work we do is as <Link to="/startup-web-application-development/">startup developers</Link> which includes designing and developing marketing websites and cloud-based web applications.
                            </p>
                            <p className="black-lt">
                                We also develop corporate marketing websites using <Link to="/gatsby-developer/">Gatsby</Link> and have experience with both <Link to="/headless-cms/">headless cms development</Link> and Django.
                            </p>    
                            <p>We've also recently started using <Link to="/webflow-developer/">Webflow</Link> to offer low-code or no-code solutions for projects where the requirements are a good match.</p>    
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Start your project
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Where are your web developers based? <ChevronDown />
                                </>
                                }
                            >
                                <p>We're a senior onshore web development team. Our web developers are based in Australia.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you offer ongoing website support? <ChevronDown />
                                </>
                                }
                            >
                                <p>We won’t leave you in the trenches on your own. We’re happy to manage servers and website hosting for you as well as implementing upgrades and changes to your website on a regular basis.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you only work with startups? <ChevronDown />
                                </>
                                }
                            >
                                <p>No. Developing early stage startups takes up about 80% of time. The rest is filled with developing websites, chatbots and applications for leading brands, Government, Australian Universities and later stage funded, and acquired, startups.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you offer web design services? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. We offer a <Link to="/web-design/">web design</Link> and product design service. However, if you've already had your website designed we're happy to develop it for you based on supplied design files.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Where do you host your websites? <ChevronDown />
                                </>
                                }
                            >
                                <p>Our preference is to use Amazon Web Services. Regardless of the product being a static website, a CMS solution or a fully featured web application, the AWS suite of products provides all the tools we need to move quickly with tools we trust.</p>
                                <p>This only changes if we're developing your website on Webflow. In that case, your website will be hosted on Webflow.</p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="If you are looking for an experienced web developer in Australia we'd love to discuss your project with you."
        />
    </Layout>
)

export default WebDevPage